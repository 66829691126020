import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import FacebookSVG from "../../../images/social/facebook.inline.svg";
import TwitterSVG from "../../../images/social/twitter.inline.svg";

const Wrapper = styled.div`
  float: right;
  padding: 16px 0;
`;

const Link = styled.a`
  margin-left: 20px;
`;

const SVGIcon = styled.svg`
  width: 32px;
  height: 32px;
  fill: #97979D;
  stroke: #97979D;
  
  transition: fill 500ms, stroke 500ms;

  &:hover {
    fill: #3cbf0a;
    stroke: #3cbf0a;
  }
`;

const Social = () => {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                    shareText
                }
            }
        }
    `
  );

  return (
    <Wrapper>
      <Link
        href={`https://twitter.com/intent/tweet?text=${site.siteMetadata.shareText}&url=${site.siteMetadata.siteUrl}`}
        rel="noopener noreferrer" target="_blank"
      >
        <SVGIcon as={TwitterSVG}/>
      </Link>

      <Link
        href={`https://www.facebook.com/sharer/sharer.php?u=${site.siteMetadata.siteUrl}`}
        rel="noopener noreferrer" target="_blank"
      >
        <SVGIcon as={FacebookSVG}/>
      </Link>
    </Wrapper>
  );
};

export default Social;
