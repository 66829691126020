import PropTypes from "prop-types";
import React from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "../../theme";
import NavBar from "../NavBar";

const Main = styled.main`
  overflow: hidden;
`;

const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => props.theme.textColor};
    background-color: ${props => props.theme.backgroundColor};
  }
`;

const Layout = ({ showEscapeBar, onEscape, children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle/>
    <a className="sr-only" href="#content">
      Skip to content
    </a>
    <NavBar showEscapeBar={showEscapeBar} onEscape={onEscape}/>
    <Main id="content">
      {children}
    </Main>
  </ThemeProvider>
);

Layout.propTypes = {
  showEscapeBar: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Layout;
