import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import LogoSVG from "../../images/tj-logo.min.svg";
import EscapeBar from "./EscapeBar";
import Social from "./Social";

const Wrapper = styled.div`
  width: 100%;
  z-index: 11;
`;

const Container = styled.div`
    max-width: ${props => props.theme.grid.containerWidth}px;
    margin: auto;
    position: relative;
    z-index: 2;
`;

const Nav = styled.nav`
  display: block;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 9px 0 rgba(51, 55, 69, 0.33);
  position: relative;
  background-color: #fff;
  z-index: 11;
  padding: 0 10px;
`;

const Logo = styled.a`
  float: left;
  border: none;
  background: url(${LogoSVG}) no-repeat center center;
  display: block;
  width: 111px;
  height: 24px;
  box-sizing: content-box;
  padding: 20px 0;
  border: 0;
`;

const NavBar = ({ showEscapeBar, onEscape }) => (
  <Wrapper>
    <Nav>
      <Container>
        <Logo title="Totaljobs" href="/">
          <span className="sr-only">
            Totaljobs
          </span>
        </Logo>

        <Social/>
      </Container>
    </Nav>

    <EscapeBar show={showEscapeBar} onEscape={onEscape}/>
  </Wrapper>
);

NavBar.propTypes = {
  showEscapeBar: PropTypes.bool
};

NavBar.defaultProps = {
  showEscapeBar: false
};

export default NavBar;
