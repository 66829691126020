import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  opacity: 1;
  position: fixed;
  top: ${props => props.show ? 0 : -100}%;
  left: 0;
  width: 100%;
  transition: top 500ms;
  z-index: 10;
`;

const Button = styled.button`
    color: #fff;
    background-color: ${props => props.theme.colors.green};
    width: 100%;
    display: block;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    transition: background-color 500ms;
    border: none;
    
    ${props => props.theme.fontSize(20)}
  
    &:hover {
      background-color: ${props => props.theme.colors.darkGreen};
      text-decoration: none;
      color: #fff;
    }
`;

const EscapeBar = ({ show, onEscape }) => {
  const onClick = () => {
    onEscape();
  };

  return (
    <Wrapper show={show}>
      <Button onClick={onClick}>
        Escape the trap!
      </Button>
    </Wrapper>
  );
};

EscapeBar.propTypes = {
  show: PropTypes.bool
};

EscapeBar.defaultProps = {
  show: false
};

export default EscapeBar;
