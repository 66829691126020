import CountUp from "react-countup";
import { css } from "styled-components";

const breakpoints = {
  desktop: {
    width: 1000,
    columnWidth: 65,
    columns: 12,
    gutter: 20
  },
  tablet: {
    width: 660,
    columnWidth: 70,
    columns: 8,
    gutter: 14
  },
  mobile: {
    width: 230,
    columnWidth: 65,
    columns: 4,
    gutter: 10
  }
};

const fontSizes = {
  16: {
    mobile: {
      size: 16,
      height: 16
    },
    tablet: {
      size: 16,
      height: 16
    },
    desktop: {
      size: 16,
      height: 16
    }
  },
  18: {
    mobile: {
      size: 18,
      height: 18
    },
    tablet: {
      size: 18,
      height: 18
    },
    desktop: {
      size: 18,
      height: 18
    }
  },
  20: {
    mobile: {
      size: 18,
      height: 32
    },
    tablet: {
      size: 20,
      height: 34
    },
    desktop: {
      size: 20,
      height: 34
    }
  },
  24: {
    mobile: {
      size: 20,
      height: 32
    },
    tablet: {
      size: 24,
      height: 36
    },
    desktop: {
      size: 24,
      height: 36
    }
  },
  34: {
    mobile: {
      size: 24,
      height: 34
    },
    tablet: {
      size: 34,
      height: 54
    },
    desktop: {
      size: 34,
      height: 54
    }
  }
};

const theme = {
  "backgroundColor": "#FFFFFF",
  "textColor": "#292A36",
  "grid": {
    "containerWidth": 1000,
    "columnWidth": 65,
    "gutterWidth": 20,
    "columns": 12
  },
  "colors": {
    "grey": "#F5F5F5",
    "pink": "#A94A7D",
    "purple": "#333745",
    "women": "#009696",
    "men": "#F06C30",
    "green": "#3CBF0A",
    "darkGreen": "#169000",
    "turquoise": "#8B8FA0"
  },
  "animations": {
    "duration": 1000,
    fade: {
      duration: 1000
    }
  },
  breakpoints,
  desktop: (...args) => css`
    @media (min-width: ${breakpoints.desktop.width}px) {
       ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (min-width: ${breakpoints.tablet.width}px) {
       ${css(...args)};
    }
  `,
  tabletDown: (...args) => css`
    @media (max-width: ${breakpoints.desktop.width}px) {
       ${css(...args)};
    }
  `,
  fontSizes,
  fontSize: (size) => css`
    font-size: ${fontSizes[size].mobile.size}px;
    line-height: ${fontSizes[size].mobile.height}px;
    
    ${props => props.theme.tablet`
      font-size: ${fontSizes[size].tablet.size}px;
      line-height: ${fontSizes[size].tablet.height}px;
    `}
    
    ${props => props.theme.desktop`
      font-size: ${fontSizes[size].desktop.size}px;
      line-height: ${fontSizes[size].desktop.height}px;
    `}
  `
};

// setting countups default duration to 1
CountUp.defaultProps.duration = 1;

export default theme;
